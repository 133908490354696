// add your site specific javascript here
(function ( $ ) {
	'use strict';

	$.ajaxSetup( {
		cache: true
	} );


	$(window).ready(function(){

		setTimeout(function(){
			$('#loadingOverlay').fadeOut(500,function(){
			})
		},700);
	})

	//For crawler
	if ( !window.JS_ENABLED ) {
		$.ajax( {
			url:      window.makeUrl( { module: 'eshop', action: 'setJavascriptEnabled' } ),
			type:     'post',
			dataType: 'json'
		} );
	}


	if($('.lazy[data-src]').length){
		[].forEach.call(document.querySelectorAll('.lazy[data-src]'), function(img) {
			img.setAttribute('src', img.getAttribute('data-src'));
			img.onload = function() {
				img.removeAttribute('data-src');
			};
		});		
	}		

	if($('.lazybg[data-src]').length){
		[].forEach.call(document.querySelectorAll('.lazybg[data-src]'), function(element) {
			element.setAttribute('style', element.getAttribute('data-style'));
			element.onload = function() {
				element.removeAttribute('data-style');
			};
		});		
	}		

	// -----------------------------------------------------------------------------------------------------------------
	// Anything to be initialized on document ready goes here

	$( function () {
		//First modal registration
		$( '#registrationModalThanks' ).modal( 'show' );

		$("#closeIcon").on('click',function(){
			$("#searchContainer").fadeOut();
		});
		$("#searchOpener").on('click',function(){
			$("#searchContainer").show();
		});


		$(".customscrollbar").mCustomScrollbar();


		var headerHeight = $("#site_header").height();
		var footerHeight = $("#site_footer").height();

		// if ( SGL_JS_MANAGER === 'home' || SGL_JS_MANAGER === 'cart'){
			var siteMain = $("#site_main");
			if($("#breadcrumb-container").length > 0)
				siteMain = $("#breadcrumb-container");
			siteMain.css({'padding-top':headerHeight+'px'});
		// }

		/*$('#spyscrollcontainer').stickySidebar({
    topSpacing: 0,
    bottomSpacing: 20
  });
	*/	





	if(IS_MOBILE === false){	
		$('#spyscrollcontainer').stickr({
			duration: 10,
			offsetTop: headerHeight,
			offsetBottom: 0,
			easing:'linear'
		});
	}
		else
		{
		$('#spyscrollcontainer').stickr({
			duration: 10,
			offsetTop: 0,
			offsetBottom: 0,
			easing:'linear'
		});			
			
		}

		// If we have an "active" tab we start with that one selected, otherwise we select the first one
		$( '.nav-tabs' ).each( function () {
			var $activeTab = $( this ).find( '.active [data-toggle="tab"]:first' );

			if ( $activeTab.length ) {
				$activeTab.tab();
			} else {
				$( this ).find( '[data-toggle="tab"]:first' ).tab();
			}
		} );

		// Carousel Settings - Home
		$( '.carousel' ).carousel( {} );

		// SWIPE CAROUSEL
		var $carousel = $( '.carousel' );
		if ( $carousel.length ) {
			$carousel.swiperight( function () {
				$( this ).carousel( 'prev' );
			} );
			$carousel.swipeleft( function () {
				$( this ).carousel( 'next' );
			} );
		}
	} );

	$( function () {
		$( this ).find( '[data-zg-role="search-input"]').each( function(){
			//Bind the search function
			$( this ).on('input paste', function(){


				// Retrieve the input field text and reset the count to zero
				var filter = $(this).val(), count = 0;

				// Loop through the comment list
				$('[data-zg-role="search-items"]').each(function(){

					// If the list item does not contain the text phrase fade it out
					if ($(this).text().search(new RegExp(filter, "i")) < 0) {
						$(this).fadeOut();

						// Show the list item if the phrase matches and increase the count by 1
					} else {
						$(this).show();
						//if is the first visible I also show the function definition
						/*if(count == 0){
								console.log($(this).find('a'));
							}*/

						count++;
					}
				});

				// Update the count
				if(count > 0){
					$("#noResult").addClass('hide');
				}
				else{
					$("#noResult").removeClass('hide');
				}
				$("#filter-count").text(count);
				$(".count-hide").removeClass('hide');

			});
		}
																												);

	});

	//Scroll slowly to the target
	$( function () {
		$('[data-zg-scrollTo="slowly"]').click(function () {
			var target = $(this).data('zg-target'),
					href = $(this).attr("href"),
					offsetInserted = $(this).data('zg-offset'),
					offset = $(target).offset().top;

			if( offsetInserted ){
				offset -= offsetInserted;
			}
			$('html, body').animate({scrollTop: offset}, 1000);
			window.location.hash = href;
		});
	});

	/*
	if ( SGL_JS_MANAGER === 'home') {	

		console.log('sono in homepage');
		if(ZG_CONFIG.showNewsletterPopup === true)
		{

			var valCookie = window.zgReadCookie("nlpopupmodalCookie" );

			if (valCookie != 1){
				console.log('cookie not set');

				$(window).load(function() {
					$('#nlpopup').modal('show');
					$('#nlpopup.modal').on('show.bs.modal',function(){
						$('#nlpopup').css({'display':'block !important'});
					});						
				});

				$('#nlpopup').on('hide.bs.modal', function() {
					setCookie("nlpopupmodalCookie","1", 1);
				});

			}
		}
	}*/

	// Redirecting to the "Messages" tab in the My Acoount page when the user clicks on "View my messages"
	if ( SGL_JS_MANAGER === 'account' && window.location.hash === '#tickets' ) {
		$( '#tabs-account li a[href="#tickets"]' ).tab( 'show' );
	}

	// if(IS_MOBILE === false){	
	// 	if ( SGL_JS_MANAGER === 'home') {
	// 		$( '#site_header' ).addClass( 'transparent' );

	// 		$(window).bind('scroll', function() {

	// 			if ($(window).scrollTop() > 150) {
	// 				$('#site_header').removeClass('transparent');
	// 			}
	// 			else
	// 			{
	// 				$('#site_header').addClass('transparent');
	// 			}

	// 		});

	// 	}
	// }

$('.search-activator').on('click',function(e){
	e.preventDefault();
	$(this).siblings('.search-form-content').animate({
		'opacity':1,
		'display':'block'
	},500);
})


	function toggleIcon(e) {
		$(e.target)
		.prev('.panel-heading')
		.find(".more-less")
		.toggleClass('fa-angle-down fa-angle-up');
	}


	//$('.panel-group').on('hidden.bs.collapse', toggleIcon);
	//$('.panel-group').on('shown.bs.collapse', toggleIcon);

	$(document).on('hidden.bs.collapse','.panel-group', toggleIcon);	
	$(document).on('shown.bs.collapse','.panel-group', toggleIcon);


	// Fading out the bouncing arrow for the Home Page slider
	$( window ).scroll( function(){
		$(".arrow").css("opacity", 1 - $(window).scrollTop() / 250);
		//250 is fade pixels
	});

	$( '[data-zg-role="btn-search"]' ).click( function(){
		$( '#searchContainer').toggle( '200' );
	});


	// ===== Scroll to Top ====
	$( window ).scroll( function() {
		if ( $( this ).scrollTop() > 200) {        // If the page is scrolled more than 200px
			$( '#return-to-top' ).fadeIn( 200 );
		} else {
			$( '#return-to-top' ).fadeOut( 200 );
		}
	});
	$( '#return-to-top' ).click(function() {
		$( 'body,html' ).animate({
			scrollTop : 0
		}, 500);
	});


	//Increasing-decreasing qty input
	$( '[data-zg-role="change-qty"]' ).click( function(){
		event.preventDefault();
		var type = $( this ).data( 'type' );
		var $inputQtySibling = $( this ).parent().siblings( '[data-zg-role="quantity"]' );
		var maxQty = $inputQtySibling.data( 'max' );
		var floatQty = $inputQtySibling.data( 'floatQty' );
		var qty = $inputQtySibling.val();
		var step;

		if( floatQty ){
			step = 0.01;
		}
		else {
			step = 1;
		}
		qty = Number(qty);

		$( '[data-zg-role="change-qty"]' ).attr( 'disabled', false );
		if( type == "minus" ){
			//If qty is 0, we will disable the current minus icon. 
			//The next time this function will be run, all the change qty icons will be enabled again.
			if( qty == 0 ){
				$( this ).attr( 'disabled', true );
			}
			else{
				qty = qty - step;
			}
		}
		else {
			//If qty is equal to maxQty, we will disable the current plus icon. 
			//The next time this function will be run, all the change qty icons will be enabled again.
			if( qty == maxQty ){
				$( this ).attr( 'disabled', true );
			}
			else {
				qty = qty + step;
			}
		}

		$( this ).parent().siblings( '[data-zg-role="quantity"]' ).val( qty );
	});

	function get_hostname(url) {
		var m = url.match(/^(http|https):\/\/[^/]+/);
		return m ? m[0] : null;
	}

	$(window).load(function() {
		jQuery('.svg').each(function(){
			var $img = jQuery(this);
			var imgID = $img.attr('id');
			var imgClass = $img.attr('class');
			var imgTitle = $img.attr('title');
			var imgURL = $img.attr('src');

			var urlCDN = get_hostname(imgURL);
			var imgPath = imgURL.replace(urlCDN, SGL_JS_WEBROOT);

			/*console.log('Img URL: '+imgURL);
			console.log('Root src: '+SGL_JS_WEBROOT);
			console.log('SVG root: '+urlCDN);*/

			jQuery.get(imgPath, function(data) {
				// Get the SVG tag, ignore the rest
				// console.log(data);
				var $svg = jQuery(data).find('svg');

				// Add replaced image's ID to the new SVG
				if(typeof imgID !== 'undefined') {
					$svg = $svg.attr('id', imgID);
				}
				// Add replaced image's classes to the new SVG
				if(typeof imgClass !== 'undefined') {
					$svg = $svg.attr('class', imgClass+' replaced-svg');
				}

				if(typeof imgTitle !== 'undefined') {
					$svg = $svg.attr('title', imgTitle);
				}

				// Remove any invalid XML tags as per http://validator.w3.org
				$svg = $svg.removeAttr('xmlns:a');

				// Check if the viewport is set, else we gonna set it if we can.
				if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
					$svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
				}

				// Replace image with new SVG
				$img.replaceWith($svg);

			}, 'xml');

		});
	});


	/* [codice per aprire la gallery] del colore giusto, a partire dalla foto grande visualizzata ---- VERSIONE DESKTOP*/
	$( document ).on('zg.product.optionSelected', function (e,option) {

		$(".prodimg").on('click',function(e){
			e.preventDefault();
		});
	})

	$('#navbar-collapse-main').on('shown.bs.collapse', function () {
		$('body').addClass('bodyblock');	
	})
	$('#navbar-collapse-main').on('hidden.bs.collapse', function () {
		$('body').removeClass('bodyblock');	
	})

	if(IS_MOBILE)
	{
		$(document).ready(function() {		
			$("#zoomMain").click(function() { 

				var id_colore =  $("[data-option-id='4']").find('.active').data('value');
				var id_foto = $(".thumbnail_.active img").attr('alt').split(' ')[1]-1;

				//console.log('Id Colore: '+id_colore);
				//console.log('Immagine: '+id_foto);

				var options = {					
					/*history: false,
								focus: false,
								showAnimationDuration: 0,
								hideAnimationDuration: 0,*/
					fullscreenEl: false,
					history: false,
					index: id_foto						
				};

				//console.log(options);


				var pswpElement = document.querySelectorAll('.pswp')[0];

				//console.log(pswpElement);

				var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, window["pswpItem_" + id_colore], options);

				gallery.listen('beforeChange', function() {
					$("#captionBMphoto").html(gallery['currItem']['title']);						
					$("#boxPhotoSwipeBM_counter").html(1+gallery.getCurrentIndex()+" / "+gallery['items'].length);						
				});

				gallery.init();					

				//"#boxPhotoSwipeBM_next").click(function() { gallery.next();});
				//"#boxPhotoSwipeBM_prev").click(function() { gallery.prev();});

			});	
		});	


	}
	else
	{
		$( document ).on('zg.product.optionSelected', function (e,option) {

			//console.log('Opzione selezionata');
			var optionID = option.optionId;
			var optionValue = option.value;
			var elementShown = 'productSlideGallery'+optionValue;	
			//console.log('Element to be shown:'+elementShown);

			$(".prodimg").click(function() { 
				//console.log('img cliccata');
				//$('#zoomMain').on('tap', function(){

				//var id_colore = $(".image-option.active").attr('data-value');

				var id_colore =  $("[data-option-id='4']").find('.active').data('value');
				var id_foto = $(".thumbnail_.active img").attr('alt').split(' ')[1]-1;

				//console.log('Id Colore: '+id_colore);
				//console.log('Immagine: '+id_foto);

				var options = {					
					/*history: false,
								focus: false,
								showAnimationDuration: 0,
								hideAnimationDuration: 0,*/
					fullscreenEl: false,
					history: false,
					index: id_foto						
				};

				//console.log(options);


				var pswpElement = document.querySelectorAll('.pswp')[0];

				//console.log(pswpElement);

				var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, window["pswpItem_" + id_colore], options);

				gallery.listen('beforeChange', function() {
					$("#captionBMphoto").html(gallery['currItem']['title']);						
					$("#boxPhotoSwipeBM_counter").html(1+gallery.getCurrentIndex()+" / "+gallery['items'].length);						
				});

				gallery.init();					

				//"#boxPhotoSwipeBM_next").click(function() { gallery.next();});
				//"#boxPhotoSwipeBM_prev").click(function() { gallery.prev();});

			});	

			/*
			if(SGL_JS_MANAGER === 'product')
			{
			var videoArray = window.videoProdDetails;


			if(videoArray)
			{
				console.log('Ho trovato un array video e la variante colore è: '+videoArray.videoVariant+'/'+option.optionId);
				if($("#videoprodcontainer").length == 0)
				{

					var theVideoBox = '<div class="col-xs-4 col-md-6" id="videoprodcontainer">';
					theVideoBox += '<a href="'+videoArray.videoProd+'" data-toggle="modal" data-target="#videoProdModal"><img src="'+videoArray.imgPlaceholderVideo+'" class="img-responsive"></a>';
					theVideoBox += '</div>';
					$('.row.imgs-gallery').append(theVideoBox);
				}
			}		

		}
			*/

		});	

	}
	$("#theclaims").slick({
		dots:false,
		cssEase: 'linear',
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		autoplay: true,
	});



	$('.suggestedProducts').slick({
		dots:false,
		cssEase: 'linear',
		arrows: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					arrows: false,
					dots:true
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					arrows: false,
					dots:true
				}
			}
		]
	});

	$(window).load(function() {

		$( document ).on('zg.product.optionSelected', function (e,option) {

			var optionID = option.optionId;
			var optionValue = option.value;
			var elementShown = 'productSlideGallery'+optionValue;		
			
			
			/*
			if(videoProdDetails && videoProdDetails[option.value])
			{
				console.log('Ho trovato un array video e la variante colore è: '+videoProdDetails[option.value].videoVariant+'/'+option.optionId);
				if($("#videoprodcontainer").length == 0)
				{
					console.log(videoProdDetails[option.value]);
					var theVideoBox = '<div class="col-xs-4 col-md-6" id="videoprodcontainer">';
					theVideoBox += '<a href="'+videoProdDetails[option.value].videoProd+'" data-toggle="modal" data-target="#videoProdModal"><img src="'+videoProdDetails[option.value].imgPlaceholderVideo+'" class="img-responsive"></a>';
					theVideoBox += '</div>';
					$('.row.imgs-gallery').append(theVideoBox);
				}
			}
*/



			if(optionID == 4)
			{
				setTimeout(function(){ 
					sliderInit($('#'+elementShown)); 
				}, 10);
			}



		});


		$( document ).on('zg.getProductInfo.success', function (e, products, filters, $container) {
			if( 
				$container.length && 
				$container.hasClass('modal-body')
			)
			{
				//console.log('ScrollerProds');
				//console.log($container);
				var slides = $container.find('.photoslider');
				var slidesID = slides.attr('id'); 
				//console.log('id: '+slidesID);
				//INIT SLICK CAROUSEL

				//$('#'+slidesID).slick('unslick');
				setTimeout(function(){ 

					$('#'+slidesID).slick('unslick');
					sliderInit($('#'+slidesID)); 
					//onsole.log('avvio slick');
				}, 10);
			}


		}); 

	});
	
function destroyCarousel() {
  if ($('.photoslider').hasClass('slick-initialized')) {
    $('.photoslider').slick('unslick');
		//console.log('destroy slick');
  }      
}

	//$( document ).on('zg.getProductInfo.success', function (e, products, filters, $container) {

		//console.log('Filters applied - also on startup');
		

	//}); 

	function sliderInit(slickContainer){
		slickContainer.slick({
			autoplay: false,
			dots:false,  
			fade: true,
			cssEase: 'linear',
			arrows: true
		});				
	}


	function firstSecond(first, callback) {
		//console.log('first f');
		callback();
	}




	if(SGL_JS_MANAGER == 'home')
	{
		/*	var userFeed = new Instafeed({
			get: 'user',
			userId: '2270816184',
			accessToken: '2270816184.8f5523e.c9983f63b72f4cda9688c48ffd4618e2',
			template: '<div class="col-xs-12 col-md-4 instapic"><div class="square-img"><div class="crop"><a href="{{link}}" target="_blank"><img src="{{image}}" class="img-responsive" /></a></div></div></div>',
			resolution: 'standard_resolution',
			limit:3,
			after: function () {


				$('.socialcards').slick({
					dots: false,
					infinite: false,
					arrows:false,
					speed: 300,
					slidesToShow: 3,
					slidesToScroll: 1,
					cssEase: 'linear',
					responsive: [
						{
							breakpoint: 480,
							settings: {
								dots:true,
								slidesToShow: 1,
								slidesToScroll: 1
							}
						}
					]
				});				

			}
		});
		userFeed.run();
*/
	}


	/*
	$('.dropdown').on('show.bs.dropdown', function(e){
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown(300);
	});

	$('.dropdown').on('hide.bs.dropdown', function(e){
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp(300);
	});*/
	$(document).ready(function(){
		function removeFromCartDL(id,nome,categoria,prezzo)
		{
			dataLayer.push({
				'event': 'removeFromCart',
				'ecommerce': {
					'remove': {                               // 'remove' actionFieldObject measures.
						'products': [{                          //  removing a product to a shopping cart.
							'name': ''+nome+'',
							'id': ''+id+'',
							'price': ''+prezzo+'',
							'brand': 'Umbro',
							'category': ''+categoria+''
						}]
					}
				}
			});

			//console.log('DL removeFromCart triggered');
		}
	});

	// confirm remove product from cart
	$(".btn-remove").on('click',function(e){
		var form = $(this).parent('form');
		var id = form.data('id');
		var name = form.data('prodname');
		var price = form.data('prodprice');
		var categoria = form.data('categoria');

		//console.log('Remove -> Cat:'+categoria+', id:'+id+', prezzo:'+price+',name:'+name);

		var result = confirm(JS_TRANSLATIONS.deleteProductCart);
		if (result) {
			//removeFromCartDL(id,name,categoria,price);

			dataLayer.push({
				'event': 'removeFromCart',
				'ecommerce': {
					'remove': {                               // 'remove' actionFieldObject measures.
						'products': [{                          //  removing a product to a shopping cart.
							'name': ''+name+'',
							'id': ''+id+'',
							'price': ''+price+'',
							'brand': 'Umbro',
							'category': ''+categoria+''
						}]
					}
				}
			});

			return true;
		}
		else
			return false;
	});

	$("#filterMobileBtn").on('click',function(e){
		e.preventDefault();
		$("#mobile-filters-overlay").fadeIn('fast',function(){

			$("#mobile-filters").animate({
				'right':0
			});

		});
	});

	$(".closex, #mobile-filters-footer .link").on('click',function(e){
		e.preventDefault();
		$("#mobile-filters").animate({
			'right':'-300px'
		},function(){
			$("#mobile-filters-overlay").fadeOut('fast');			
		});
	});

	$(document).ready(function(){



		function addCartDL(id,nome,categoria,prezzo)
		{
			dataLayer.push({
				'event': 'addToCart',
				'ecommerce': {
					'currencyCode': 'EUR',
					'add': {                                // 'add' actionFieldObject measures.
						'products': [{                        //  adding a product to a shopping cart.
							'name': ''+nome+'',
							'id': ''+id+'',
							'price': ''+prezzo+'',
							'brand': 'Umbro',
							'category': ''+categoria+''
						}]
					}
				}
			});
			//console.log('DL addToCart triggered');
		}

		$('#addclickable').on('click', function() {
			if ($('#add-to-cart:disabled').length > 0) {
				$("#sizeAlert").removeClass('hidden');
			} else {
				$('#add-to-cart').click();
				$("#sizeAlert").addClass('hidden');
				var id = $('#add-to-cart').data('prodid');
				var name = $('#add-to-cart').data('name');
				var brand = $('#add-to-cart').data('brand');
				var price = $('#add-to-cart').data('price');
				var categoria = $('#add-to-cart').data('categoria');
				addCartDL(id,name,categoria,price);
			}
		});

	});

// fix menu per risoluzioni intermedie

	const winw = $(document).width();
	if ( winw > 768 && winw <= 1080)
	{
		// $('.menu-holder > .menublock').css({
		// 	'border-bottom':'1px solid #efefef'
		// });
		if(!IS_MOBILE){
			// $('.menu-holder-not-mobile > .menublock').wrapAll("<div class='small-screen-fixer'></div>");
		}
		
	}


	if(!IS_MOBILE)
	{

		
		$('.menu-holder > .navbar-nav > li > .dropdown-toggle')
		.hover(
			function(e) {
				if ($(document).width() > 768) {
					e.preventDefault();
					$('.menu-holder > .navbar-nav > li').removeClass('open');

					$(this).parent().addClass('open');
				}
			}
			// ,
			// function(e) {
			// 	if ($(document).width() > 768) {
			// 		e.preventDefault();
			// 		$(this).parent().removeClass('open');
			// 	}
			// }																													
		);
		
		// $('.menu-holder > .navbar-nav > li > .dropdown-toggle.search-activator-cta')
		// .hover(function(e){
		// 	e.preventDefault();
		// 	e.stopPropagation();
		// })
		// .toggle(
		// 	function(e) {
		// 		if ($(document).width() > 768) {
		// 			e.preventDefault();
		// 			$('.menu-holder > .navbar-nav > li').removeClass('open');

		// 			$(this).parent().addClass('open');
		// 		}
		// 	}																													
		// );


		$('.menu-holder > .navbar-nav > li > .dropdown-toggle').click(function(e) {
			if ($(document).width() > 768) {
				e.preventDefault();
				e.stopPropagation();
				//console.log('click su main voice');

				var url = $(this).attr('href');
				if (url !== '#') {
					window.location.href = url;
				}

			}
		});


		$(".menu-holder > .nav, .menu-holder > .nav > li > .dropdown-menu").mouseleave(function() {
			$(".menu-holder > .nav > li").removeClass('open');
		})


	}


	if(IS_MOBILE)
	{
		$("#prodlist-sciam").slick({
			dots:true,
			arrows: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: false
		});
	}


	//video for variants

	/*
	if ( SGL_JS_MANAGER === 'product') {
		$( document ).on('zg.product.optionSelected', function (e,option) {
			console.log('MAnager: '+SGL_JS_MANAGER);

			$("#sizeAlert").addClass('hidden');

			var optionID = option.optionId;
			var optionValue = option.value;
			var elementShown = 'productVideoBox_'+optionValue;		

			console.log('Element Shown: '+ videoProdDetails[optionValue]);

			if(videoProdDetails[option.value])
			{
				console.log('2 Ho trovato un array video e la variante colore è: '+videoProdDetails[option.value].videoVariant+'/'+option.optionId);
				if($("#videoprodcontainer").length == 0)
				{
					console.log(videoProdDetails[option.value]);
					var theVideoBox = '<div class="col-xs-4 col-md-6" id="videoprodcontainer">';
					theVideoBox += '<a href="'+videoProdDetails[option.value].videoProd+'" data-toggle="modal" data-target="#videoProdModal"><img src="'+videoProdDetails[option.value].imgPlaceholderVideo+'" class="img-responsive"></a>';
					theVideoBox += '</div>';
					$('.row.imgs-gallery').append(theVideoBox);
				}
			}

		});	
	}

	*/

	// video for variant Modal
	if($('#videoProdModal'))
	{

		var video = document.getElementById("videoProdotto");

		$('#videoProdModal').on('show.bs.modal', function (e) {
			//console.log('Ho aperto la modale video');
			video.currentTime = 0;
			video.play();

		})
	}

	if(IS_MOBILE)
	{
		// AddReadMore();
	}


	// progetto Academy hide/show form fields
	// if($('.academyform').length > 0){
		$('.only4contactsLabel').css({'display':'none'});
		$('.only4contacts').prop('required',false).hide();

		$("#iscatalogoFormBtn").on('click',function(e){
			$('.formSelector').removeClass('disabled');
			$(this).addClass('disabled');
			$('.only4contactsLabel').css({'display':'none'});
			$('.only4contacts').prop('required',false).hide();
			var didascaliaContent = $('#hiddenDidaTitleCat').val();
			var sideImg = $('#hiddenSidemgCat').val();
			$('.didascaliaTitolo').html(didascaliaContent);
			$('#sideImg').css({'background-image':'url('+sideImg+')'});
			$("#mce-RICHIESTA").val('catalogo');
			$("#mc-embedded-subscribe").val('SCARICA IL CATALOGO TEAMWEAR');
		});

		$("#iscontattiFormBtn").on('click',function(e){
			$('.formSelector').removeClass('disabled');
			$(this).addClass('disabled');
			$('.only4contactsLabel').css({'display':'inline-block'});
			$('.only4contacts:not(#mce-MESSAGGIO)').prop('required',true);
			$('.only4contacts').show();
			var didascaliaContent = $('#hiddenDidaTitleCont').val();
			var sideImg = $('#hiddenSidemgCont').val();
			$('.didascaliaTitolo').html(didascaliaContent);
			$('#sideImg').css({'background-image':'url('+sideImg+')'});
			$("#mce-RICHIESTA").val('contatti');
			$("#mc-embedded-subscribe").val('CONTATTACI');
		});

	// }"

	// $(".eventoNews img").on('click',function(e){
	// 	e.preventDefault();
	// 	console.log('This',$(this));
	// 	const immagine = $(this).attr('src');
	// 	console.log('Immagine',immagine);
	// 	$(document).find("#zoom-modal").find('#zoom-content').html('<img src="'+immagine+'" class="img-responsive">');
	// 	$(document).find('#zoom-modal').modal('show');
	// });

$('#zoom-modal').on('show.bs.modal', function (event) {
  var e = $(event.relatedTarget) // Button that triggered the modal
  const immagine = e.attr('src');
  const title = e.attr('data-title');


  var modal = $(this);
  modal.find('#zoom-content').html('<img src="'+immagine+'" class="img-responsive">');
  modal.find('#zoom-title').text(title);
//   modal.find('.modal-title').text('New message to ' + recipient)
//   modal.find('.modal-body input').val(recipient)
})



    var contenutoDaCopiare = $("#discountCode");

    // Seleziona l'elemento cliccabile per copiare il contenuto
    var bottoneCopia = $("#discountCopy");

    // Aggiungi un listener per l'evento click sul bottone
    bottoneCopia.on("click", function() {
        // Copia il testo contenuto nell'elemento da copiare
        var testoDaCopiare = contenutoDaCopiare.text();
		console.log('btn copy clicked');

        // Utilizza l'API Clipboard per copiare il testo nella clipboard
        navigator.clipboard.writeText(testoDaCopiare)
            .then(function() {
                // Successo
                console.log("Testo copiato nella clipboard: " + testoDaCopiare);
				                
				bottoneCopia.addClass('clicked');
                // Distruggi il messaggio di conferma dopo 2 secondi
                setTimeout(function() {
                    bottoneCopia.removeClass('clicked');
                }, 2000);
            })
            .catch(function(err) {
                // Gestisci eventuali errori
                console.error("Errore durante la copia nella clipboard: ", err);
            });
    });





})( jQuery );

function setCookie(strName, strValue, expireDays) {
	var d = new Date();
	d.setTime(d.getTime() + (expireDays * 604800000));
	var expires = "expires=" + d.toGMTString();
	document.cookie = strName + "=" + strValue + "; " + expires + "; path=/";
}

function getCookie(cname) {
	var name = cname + "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for(var i = 0; i <ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}	




if(SGL_JS_MANAGER == 'home')
{

	// Initialize library
	// var lib = new Nanogram();
	// buildPorfolio(lib);
}

function buildPorfolio(lib) {	
	return lib.getMediaByUsername("umbroitalia").then((response) => {
		//console.log(response);
		var allphotos = response.profile.edge_owner_to_timeline_media.edges;



		var photos = allphotos.slice(0, 4);
		var items = [];

		for (var i = 0; i <= photos.length - 1; i++) {
			var current = photos[i].node;

			var div = document.createElement('div');
			div.classList.add('col-6');
			div.classList.add('col-sm-3');
			div.classList.add('instapic');
			var link = document.createElement('a');
			var img = document.createElement('img');
			img.classList.add('img-responsive');

			var thumbnail = current.thumbnail_resources[4];
			var imgSrc = thumbnail.src;
			var imgWidth = thumbnail.config_width;
			var imgHeight = thumbnail.config_height;
			var imgAlt = current.accessibility_caption;

			var shortcode = current.shortcode;
			var linkHref = 'https://www.instagram.com/p/' + shortcode;

			div.classList.add('portfolio__item');

			var squareDiv = document.createElement('div');
			var cropDiv = document.createElement('div');
			squareDiv.classList.add('square-img');
			cropDiv.classList.add('crop');

			div.appendChild(squareDiv);
			squareDiv.appendChild(cropDiv);

			img.classList.add('portfolio__img');
			img.src = imgSrc;
			img.width = imgWidth;
			img.height = imgHeight;
			img.alt = imgAlt;

			link.classList.add('portfolio__link');
			link.href = linkHref;
			link.target = '_blank';

			link.appendChild(img);
			// div.appendChild(link);
			cropDiv.appendChild(link);

			items.push(div);
		}

		// Create container for our portfolio
		var container = document.createElement('div');
		container.id = 'portfolio';
		container.classList.add('portfolio');
		container.classList.add('row');

		// Append all photos to our container
		for (var j = 0; j <= items.length - 1; j++) {
			container.appendChild(items[j]);
		}

		// Append our container to body
		var igwall = document.getElementById('instafeed');

		igwall.appendChild(container);
	}).catch(function (error) {
		console.log(error);
	})
}

if(SGL_JS_MANAGER == 'product')
{

	//console.log('Product page');
	var dataIcons = $('[data-icon]');
	dataIcons.parent('ul').addClass('ulIconsLavaggi');

	dataIcons.each(function(e){
		var icoLi = $(this);
		var ico = icoLi.data('icon').replace('”', '').replace('”','');
		//console.log('Data ico found '+ico);
		var textLi = icoLi.text();
		icoLi.html('<img src="'+FOLDER_IMG+'/istruzioni_lavaggio/'+ico+'.svg" class="icoLavaggi"  />'+textLi);
	});

}

    function AddReadMore() {
        //This limit you can set after how much characters you want to show Read More.
        var carLmt = 130;
        // Text to show when text is collapsed
        var readMoreTxt = " ..."+JS_TRANSLATIONS['readmoreextend'];
        // Text to show when text is expanded
        var readLessTxt = "  "+JS_TRANSLATIONS['readlessextend'];


        //Traverse all selectors with this class and manupulate HTML part to show Read More
        $(".addReadMore").each(function() {
            if ($(this).find(".firstSec").length)
                return;

            var allstr = $(this).html();
            if (allstr.length > carLmt) {
                var firstSet = allstr.substring(0, carLmt);
                var secdHalf = allstr.substring(carLmt, allstr.length);
                var strtoadd = firstSet + "<span class='SecSec'>" + secdHalf + "</span><span class='readMore'  title='Click to Show More'>" + readMoreTxt + "</span><span class='readLess' title='Click to Show Less'>" + readLessTxt + "</span>";
                $(this).html(strtoadd);
            }

        });
        //Read More and Read Less Click Event binding
        $(document).on("click", ".readMore,.readLess", function() {
            $(this).closest(".addReadMore").toggleClass("showlesscontent showmorecontent");
        });
    }

if($('.nl_ok_response').length > 0)
{
	var observer = new MutationObserver(function(mutationsList, observer) {
		for (var mutation of mutationsList){
			//console.log('The ' + mutation.attributeName + ' attribute was modified.');
			//console.log(mutation);
			//console.log('Grazie per esserti iscritto alla newsletter di Umbro');
			window.location.href="http://www.umbroitalia.it/eshop/cms/action/view/contentId/689804/";
		}
	});
	observer.observe(document.getElementById('mce-success-response'), { attributes: true});	
}

if($('.academy_ok_response').length > 0)
{
	var observer = new MutationObserver(function(mutationsList, observer) {
		for (var mutation of mutationsList){
			//console.log('The ' + mutation.attributeName + ' attribute was modified.');
			//console.log(mutation);
			//console.log('Academy form has been sent');
			window.location.href="http://www.umbroitalia.it/eshop/cms/action/view/contentId/692667/";
		}
	});
	observer.observe(document.getElementById('mce-success-response'), { attributes: true});	
}

var fActive = '';
$('.f-all').hide();
function filterGroup(group){
	if(fActive != group){
		$('.product-preview').filter(':not('+group+')').fadeOut( "fast", function() {
			$('.product-preview').filter(group).fadeIn();
		});
		fActive = group;
		$('.f-all').show();
	}
}

$("[data-filter]").each(function(e) {
	$(this).on('click',function(e){
		var filtro = $(this).data('filter');
		//console.log('Filtro: '+filtro);
		e.preventDefault; 
		filterGroup(filtro); 
	})
});

$('.f-all').on('click',function(e){
	e.preventDefault;
	$('.product-preview').fadeIn();
	fActive = 'all';
	$(this).hide();
});


	$('.slideTacchettee').slick({
		dots:false,
		cssEase: 'linear',
		arrows: true,
		dots:true,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					arrows: false,
					dots:true
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					dots:true
				}
			}
		]
	});

	function getTimeRemaining(endtime) {
  const total = Date.parse(endtime) - Date.parse(new Date());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  
  return {
    total,
    days,
    hours,
    minutes,
    seconds
  };
}

function initializeClock(id, endtime) {
  const clock = document.getElementById(id);
  const daysSpan = clock.querySelector('.days');
  const hoursSpan = clock.querySelector('.hours');
  const minutesSpan = clock.querySelector('.minutes');
  const secondsSpan = clock.querySelector('.seconds');

  function updateClock() {
    const t = getTimeRemaining(endtime);

    daysSpan.innerHTML = t.days;
    hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
    minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
    secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);

    if (t.total <= 0) {
      clearInterval(timeinterval);
    }
  }

  updateClock();
  const timeinterval = setInterval(updateClock, 1000);
}



const deadline = "June 10 2022 18:00 GMT+0200";

// initializeClock('clockdiv', deadline);

